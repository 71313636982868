<template>
  <data-list
    ref="dataList"
    class="content lg"
    url="/admin/evaluate-select"
    entity-name="选拔"
    hide-edit
    hide-delete
    :default-query="defaultQuery"
    :add-btn="false"
    :query="`&filter[type]=${type}`"
    :custom-url="customUrl"
    :action="action"
    :columns="columns"
    :rules="rules"
    :action-width="120"
    @detail="showDetail">
    <template v-slot:query="{form}">
      <a-form-model-item label="部门">
        <select-book type="dept" v-model="form.dept" @change="getList"></select-book>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input-search allow-clear v-model="form.keyword" placeholder="输入姓名/联系电话搜索" style="width: 180px" @search="getList"/>
      </a-form-model-item>
      <a-form-model-item label="" v-if="type === 2">
        <a-select
            style="width: 120px"
            allowClear
            placeholder="性别筛选"
            @change="getList"
            :options="sexOptions"
            v-model="form.sex">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
            style="width: 120px"
            allowClear
            placeholder="学历筛选"
            @change="getList"
            :options="educationOptions"
            v-model="form.education">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
            style="width: 120px"
            allowClear
            placeholder="年龄段筛选"
            @change="getList"
            :options="ageOptions"
            v-model="form.age">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
            style="width: 120px"
            placeholder="年份筛选"
            :options="yearOptions"
            @change="handleYearChange(form)"
            v-model="form.year">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
            style="width: 120px"
            allowClear
            placeholder="季度筛选"
            @change="getList"
            :options="quarterOptions"
            v-model="form.quarter">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="综合指数">
        <a-select
            style="width: 120px"
            allowClear
            placeholder="请选择"
            :options="numberConditionOptions"
            v-model="form.judge">
        </a-select>
        <number-range v-model="form.number" style="margin-left: 8px;" v-if="form.judge === 6"/>
        <a-input-number
            :min="58"
            :max="100"
            v-model="form.number"
            placeholder="请输入"
            style="margin-left: 8px;width: 80px;"
            @keyup.enter="getList"
            v-else-if="form.judge" />
      </a-form-model-item>
    </template>
    <template v-slot:button="{form}">
      <a-button type="primary" @click="exportData(form)" :loading="exporting">{{exporting ? '正在导出' : '数据导出'}}</a-button>
    </template>
  </data-list>
</template>

<script>
import {bookAgeList, bookStatus, educationList, getEducation, getSex, sexList} from "@/common/hr/book";
import {downLoadBlobFile} from "@/common/js/tool";

export default {
  name: "HRPrefer",
  data() {
    return {
      educationOptions: [...educationList],
      sexOptions: [...sexList],
      ageOptions: [...bookAgeList],
      yearOptions: [],
      quarterOptions: [],
      numberConditionOptions: [
        {key: 1, title: '等于'},
        {key: 2, title: '小于'},
        {key: 3, title: '小于等于'},
        {key: 4, title: '大于'},
        {key: 5, title: '大于等于'},
        {key: 6, title: '介于'},
      ],
      total: 0,
      ageChartOptions: undefined,
      educationChartOptions: undefined,
      statusChartOptions: undefined,
      exporting: false,
      defaultQuery: {}
    }
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    type() {
      const type = this.$route.params.type;
      let res = 0;
      switch (type) {
        case "monk":
          res = 1;
          break;
        case "staff":
          res = 2;
          break;
      }
      return res;
    },
    columns() {
      const type = this.type;
      const res = [
        {
          title: type === 1 ? '法名' : '姓名',
          dataIndex: 'wx_user_id',
          customRender: text => <open-data type="userName" openid={text}/>,
          width: 80
        },
        {title: '部门', dataIndex: 'dept_id', customRender: (text) => <open-data type="departmentName" openid={text} />},
        {title: '年龄', dataIndex: 'age', width: 60},
        {title: '学历', dataIndex: 'edu', width: 120, customRender: getEducation},
        {title: '联系电话', dataIndex: 'mobile', width: 120},
        {title: '平均汇报次数', dataIndex: 'report_count', sorter: true},
        {title: '平均工作响应时间（h)', dataIndex: 'response_time', width: 200, sorter: true},
        {title: '平均评价得分', dataIndex: 'total_score', width: 140, sorter: true},
        {title: '人才选拔综合指数', dataIndex: 'composite_score', width: 180, sorter: true},
      ];
      if (type === 2) {
        res.splice(1, 0,  {title: '性别', width: 60, dataIndex: 'gender', customRender: text => !text || isNaN(text * 1) ? text : getSex(text)})
        res.splice(7, 0,  {title: '平均考勤正常率', dataIndex: 'normal_rate', sorter: true})
      }
      return res;
    },
    typeTextMap() {
      const type = this.type;
      return {
        name: ['寺', '单', '职'][type] || '寺',
        entry: ['入寺日期', '进单日期', '入职日期'][type] || '入寺日期',
        leave: ['离寺日期', '离单日期', '离职日期'][type] || '离寺日期',
        title: ['其他人员', '僧众档案', '职工档案'][type] || '其他人员',
      };
    },
    statusOptions() {
      return bookStatus.map(item => new Object({ value: item.value, label: item.label + this.typeTextMap.name}));
    },
    rules() {
      const typeTextMap = this.typeTextMap;
      return {
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        entry_date: [{ required: true, message: `请选择${typeTextMap.entry}`, trigger: 'change' }],
        leave_date: [{ required: true, message: `请选择日期${typeTextMap.leave}`, trigger: 'change' }],
      }
    },
  },
  created() {
    this.setYearOptions();
  },
  methods: {
    setYearOptions() {
      const start = 2024;
      const today = new Date();
      this.yearOptions = new Array(today.getFullYear() - start + 1).fill(start).map((item, i) => {
        const year = item + i;
        return {
          value: year,
          label: `${year}年`
        }
      });
      this.defaultQuery = { year: today.getFullYear() };
      this.setQuarterOptions(today.getFullYear());
      this.getList();
    },
    setQuarterOptions(year) {
      const today = new Date();
      let res = new Array(4).fill(1).map((item, i) => {
        const q = item + i;
        return {label: `${q}季度`, value: q};
      })
      if (today.getFullYear() === year) {
        const q = Math.ceil((today.getMonth() + 1) / 3) - 1;
        res = res.slice(0, q);
      }
      if (res.length > 0) {
        this.$set(this.defaultQuery, "quarter", res[0].value);
      }
      this.quarterOptions = res;
    },
    handleYearChange(form) {
      this.setQuarterOptions(form.year);
      this.getList();
    },
    exportData(form) {
      if(this.exporting) return;
      this.exporting = true;
      let fileName = this.typeTextMap.title;
      let url = '/admin/evaluate-user/select-export';
      this.$axios(url, {
        noTempleFilter: true,
        responseType: 'blob',
        params: this.getQueryParams(form),
      }).then(res => {
        downLoadBlobFile(res, fileName);
      }).finally(() => {
        this.exporting = false;
      });
    },
    getQueryParams(form) {
      const params = { type: this.type };
      if (form.education) {
        params.education = form.education;
      }
      if (form.age) {
        params[form.age] = 1;
      }
      if (form.sex && this.type === 2) {
        params.gender = form.sex;
      }
      if (form.year) {
        params.year = form.year;
      }
      if (form.quarter) {
        params.quarter = form.quarter;
      }
      if (form.judge && form.number) {
        params.composite_type = form.judge;
        params.composite_score = form.number;
      }
      return params;
    },
    showDetail({item}) {
      this.$router.push({name: 'HRBookDetail', params: {id: item.wx_user_id}})
    },
    customUrl(form) {
      let res = "";
      if (form.dept && form.dept.length > 0) {
        res += `&filter[dept_id]=${form.dept[0]}`;
      }
      if (form.keyword) {
        res += `&filter[or][0][name][like]=${form.keyword}&filter[or][1][mobile][like]=${form.keyword}`;
      }
      if (form.sex && this.type === 2) {
        res += `&sex=${form.sex}`;
      }
      if (form.education) {
        res += `&filter[edu]=${form.education}`;
      }
      if (form.age) {
        const age = bookAgeList.find(a => a.value === form.age);
        if (age.min) {
          res += `&filter[age][gte]=${age.min}`;
        }
        if (age.max) {
          res += `&filter[age][lte]=${age.max}`;
        }
      }
      if (form.year) {
        res += `&filter[year]=${form.year}`;
      }
      if (form.quarter) {
        res += `&filter[quarter]=${form.quarter}`;
      }
      if (form.judge && form.number) {
        res += this.getJudgeQuery(form.judge, form.number);
      }
      return res;
    },
    getJudgeQuery(type, number) {
      let res = "";
      switch (type) {
          // 大于
        case 1:
          res = `&filter[composite_score]=${number}`;
          break;
        case 2:
          res = `&filter[composite_score][lt]=${number}`;
          break;
        case 3:
          res = `&filter[composite_score][lte]=${number}`;
          break;
        case 4:
          res = `&filter[composite_score][gt]=${number}`;
          break;
        case 5:
          res = `&filter[composite_score][gte]=${number}`;
          break;
        case 6:
          number = number.split(",");
          if (number.length === 2) {
            res = `&filter[composite_score][gt]=${number[0]}&filter[composite_score][lt]=${number[1]}`;
          }
          break;
      }
      return res;
    },
    getList() {
      this.$refs.dataList?.getList();
    }
  }
}
</script>

<style scoped lang="less">
.book-chart-card {
  background: transparent;
}
.book-chart-box {
  height: 200px;
}
</style>
